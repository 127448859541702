// IMPORTS
@import (reference) "../settings.less";

// PAGINATION
.pagination {
    display: flex;
    justify-content: center;
    gap: 64px;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background-color: white;
        border: 1px solid @border-color;
        border-radius: 4px;
        color: @font-color;
    }

    > a {

        &.pagination-prev:after {
            transform: rotate(90deg);
        }

        &.pagination-next:after {
            transform: rotate(-90deg);
        }

        &:after {
            content: "\e911";
            font-family: icomoon;
            font-size: 24px;
            color: @header-color;
        }
    }

    ul {
        display: flex;
        gap: 4px;
    }

    li {
        display: none;

        @media (min-width: 601px) {
            display: block;
        }

        &:first-child,
        &.selected,
        &.prev-to-selected,
        &.next-to-selected,
        &:last-child {
            display: block;
        }

        &:nth-child(2):not(.next-to-selected):not(.selected):not(.prev-to-selected),
        &:nth-last-child(-n+2):not(:last-child):not(.next-to-selected):not(.selected):not(.prev-to-selected) {
            position: relative;
            display: block;

            &:before {
                content: '...';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: white;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                font-size: 22px;
            }
        }
    }

    
    li.selected > a {
        border-color: @header-color;
        color: @header-color;
        font-weight: bold;
    }

    @media(min-width: 1280px) {
        a:hover {
            border-color: @header-color;
        }
    }
}